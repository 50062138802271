console.log('Im global')

var iconCrop,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

$(function() {
    return new iconCrop();
});

iconCrop = (function() {
    function iconCrop() {
        this.updatePreview = bind(this.updatePreview, this);
        this.update = bind(this.update, this);
        var height, width;
        width = parseInt($('#icon_cropbox').width());
        height = parseInt($('#icon_cropbox').height());
        $('#icon_cropbox').Jcrop({
            aspectRatio: 1,
            setSelect: [0, 0, width, height],
            onSelect: this.update,
            onChange: this.update
        });
    }

    iconCrop.prototype.update = function(coords) {
        $('#campaign_icon_crop_x').val(coords.x);
        $('#campaign_icon_crop_y').val(coords.y);
        $('#campaign_icon_crop_w').val(coords.w);
        $('#campaign_icon_crop_h').val(coords.h);
        return this.updatePreview(coords);
    };

    iconCrop.prototype.updatePreview = function(coords) {
        var rx, ry;
        rx = 100 / coords.w;
        ry = 100 / coords.h;
        return $('#icon_preview').css({
            width: Math.round(rx * $('#icon_cropbox').width()) + 'px',
            height: Math.round(ry * $('#icon_cropbox').height()) + 'px',
            marginLeft: '-' + Math.round(rx * coords.x) + 'px',
            marginTop: '-' + Math.round(ry * coords.y) + 'px'
        });
    };

    return iconCrop;

})();


var imageCrop,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

$(function() {
    return new imageCrop();
});

imageCrop = (function() {
    function imageCrop() {
        this.updatePreview = bind(this.updatePreview, this);
        this.update = bind(this.update, this);
        var height, width;
        width = parseInt($('#image_cropbox').width());
        height = parseInt($('#image_cropbox').height());
        $('#image_cropbox').Jcrop({
            aspectRatio: 3 / 2,
            setSelect: [0, 0, width, height],
            onSelect: this.update,
            onChange: this.update
        });
    }

    imageCrop.prototype.update = function(coords) {
        $('#campaign_image_crop_x').val(coords.x);
        $('#campaign_image_crop_y').val(coords.y);
        $('#campaign_image_crop_w').val(coords.w);
        $('#campaign_image_crop_h').val(coords.h);
        return this.updatePreview(coords);
    };

    imageCrop.prototype.updatePreview = function(coords) {
        var rx, ry;
        rx = 360 / coords.w;
        ry = 240 / coords.h;
        return $('#image_preview').css({
            width: Math.round(rx * $('#image_cropbox').width()) + 'px',
            height: Math.round(ry * $('#image_cropbox').height()) + 'px',
            marginLeft: '-' + Math.round(rx * coords.x) + 'px',
            marginTop: '-' + Math.round(ry * coords.y) + 'px'
        });
    };

    return imageCrop;

})();